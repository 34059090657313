import { template as template_e4a48b6fcab64b5e88cfa32ae9647ada } from "@ember/template-compiler";
import { and } from "truth-helpers";
import PostsCountColumn from "discourse/components/topic-list/posts-count-column";
import TopicPostBadges from "discourse/components/topic-post-badges";
const PostCountOrBadges = template_e4a48b6fcab64b5e88cfa32ae9647ada(`
  {{#if (and @postBadgesEnabled @topic.unread_posts)}}
    <TopicPostBadges
      @unreadPosts={{@topic.unread_posts}}
      @unseen={{@topic.unseen}}
      @url={{@topic.lastUnreadUrl}}
    />
  {{else}}
    <PostsCountColumn @topic={{@topic}} @tagName="div" />
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostCountOrBadges;
