import { template as template_f6abcf497f964fa2b43feaeb675c716b } from "@ember/template-compiler";
const FKLabel = template_f6abcf497f964fa2b43feaeb675c716b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
