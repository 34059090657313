import { template as template_bf15040afc894798b044327f8e15d4e2 } from "@ember/template-compiler";
const FKText = template_bf15040afc894798b044327f8e15d4e2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
