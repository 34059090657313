import { template as template_cfd0f3afd1724126b3f872a1fda34c12 } from "@ember/template-compiler";
const FKControlMenuContainer = template_cfd0f3afd1724126b3f872a1fda34c12(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
